import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
    //   autoplay: true
    };
    return (
        <section id="brands" className="brands section">

        
        <Slider {...settings} style={{padding: '0 50px'}}>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/amazon.png" className="menu-img img-fluid" alt="" />  
                </div>
                <p className="ingredients">
                  Amazon Gift Card
                </p>
                <p className="price discount-txt">
                  {/* 5% Savings */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/flipkart.png" className="menu-img img-fluid" alt="" />  
                </div>
                <p className="ingredients">
                 Flipkart Gift Card
                </p>
                <p className="price discount-txt">
                  {/* ₹ 1000 */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/croma.png" className="menu-img img-fluid" alt="" />  
                </div>
                
                <p className="ingredients">
                    Croma Gift Card
                </p>
                <p className="price discount-txt">
                  {/* ₹ 1000 */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/swiggy.png" className="menu-img img-fluid" alt="" />  
                </div>
                <p className="ingredients">
                    Swiggy Gift Card
                </p>
                <p className="price discount-txt">
                  {/* ₹ 1000 */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/bigbasket.png" className="menu-img img-fluid" alt="" />  
                </div>
                <p className="ingredients">
                    Big Basket Gift Card
                </p>
                <p className="price discount-txt">
                  {/* ₹ 1000 */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/zomato.png" className="menu-img img-fluid" alt="" />  
                </div>
                <p className="ingredients">
                    Zomato Gift Card
                </p>
                <p className="price discount-txt">
                  {/* ₹ 1000 */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/zepto.jpeg" className="menu-img img-fluid" alt="" />  
                </div>
                <p className="ingredients">
                    Zepto Gift Card
                </p>
                <p className="price discount-txt">
                  {/* ₹ 1000 */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/pizzahut.png" className="menu-img img-fluid" alt="" />  
                </div>
                <p className="ingredients">
                    Pizza Hut Gift Card
                </p>
                <p className="price discount-txt">
                  {/* ₹ 1000 */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
            <div className="sld-container">
                <div className="bnd-image">
                    <img src="assets/img/brands/myntra.png" className="menu-img img-fluid" alt="" />  
                </div>
                <p className="ingredients">
                    Myntra Gift Card
                </p>
                <p className="price discount-txt">
                  {/* ₹ 1000 */}
                </p>
                <p className="price buy-btn">
                  <a className="btn-getstarted" href="#contact-form">Buy</a>
                </p>
            </div>
        </Slider>
        </section>
    );
  }