import React from 'react';
// import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
// import './App.css';
import Layout from '../../components/Layout/Layout';

function Terms() {
  return (
    <Layout>
        <section id="about" className="about section">

            <div className="container section-title" data-aos="fade-up">
            <h1>Terms & Conditions<br /></h1>
            </div>

            <div className="container">

                <div className="row gy-4 content">
                    <h1>Introduction </h1>
                    
                    <p>By purchasing, using, or redeeming our gift cards, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>
                    <h1>Purchase and Use</h1>
                    <ul>
                        <li> <span><b>Eligibility:</b> Gift cards can be purchased by individuals who are 18 years or older.</span></li>
                        <li> <span><b>Usage:</b> Gift cards are only redeemable at participating retailers.</span></li>
                        <li> <span><b>Non-transferable:</b> Gift cards are non-transferable and cannot be exchanged for cash, except where required by law.</span></li>
                        <li> <span>Voucher Buy is not responsible for lost or stolen cards and once card issued Voucher Buy is not liable.</span></li>
                    </ul>
                    <h1>Delivery</h1>
                    <ul>
                        <li> <span><b>Instant Delivery:</b> Digital gift cards are delivered instantly via email to the address provided at the time of purchase.</span></li>
                        <li> <span><b>Responsibility:</b> It is the purchaser’s responsibility to ensure the accuracy of the recipient’s email address.</span></li>
                    </ul>
                    <h1>Redemption</h1>
                    <ul>
                        <li> <span><b>Online Redemption:</b> Gift cards can be redeemed online at the specified retailer’s website.</span></li>
                        <li> <span><b>Balance Check:</b> The remaining balance on a gift card can be checked by logging into the retailer’s website or contacting their customer service.</span></li>
                    </ul>
                    {/* <h1>Expiry and Fees</h1>
                    <ul>
                        <li> <span><b>Validity:</b> Gift cards are valid for [insert validity period, e.g., 12 months] from the date of purchase.</span></li>
                        <li> <span><b>No Fees:</b> No fees will be charged for the purchase, activation, or use of gift cards. (optional)</span></li>
                    </ul> */}
                    <h1>Refunds and Cancellations</h1>
                    <ul>
                        <li> <span><b>Non-refundable:</b> Gift cards cannot be refunded or exchanged for cash.</span></li>
                        <li> <span><b>Cancellation:</b> Voucher buyreserves the right to cancel any gift card if there is suspicion of fraud or misuse.</span></li>
                    </ul>
                    {/* <h1>Liability</h1>
                    <ul>
                        <li> <span><b>Lost or Stolen Cards:</b> [Voucher buy] is not responsible for lost, stolen, or unauthorized use of gift cards.</span></li>
                        <li> <span><b>Errors:</b> In case of any error in the amount or delivery of a gift card, please contact our customer support immediately.</span></li>
                    </ul>
                    <h1>Amendments</h1>
                    <ul>
                        <li> <span><b>Changes:</b> Voucher Buy reserves the right to amend these terms and conditions at any time without prior notice. Changes will be posted on our website.</span></li>
                    </ul>
                    <h1>Governing Law</h1>
                    <ul>
                        <li> <span><b>Jurisdiction:</b> These terms and conditions are governed by the laws of [your jurisdiction]. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts of [your jurisdiction].</span></li>
                    </ul> */}
                    <h1>Contact Information</h1>
                    <ul>
                        <li> <span><b>Support:</b> For any questions or concerns regarding these terms and conditions, please contact our customer support at [contact email] or [contact phone number].</span></li>
                    </ul>
                    <p className="fst-italic">
                    By using our gift cards, you acknowledge that you have read, understood, and agreed to these terms and conditions. Thank you for choosing Voucher Buy
                    </p>
                </div>

            </div>

        </section>
    </Layout>
    
  );
}

export default Terms;
