import { isClickableInput } from "@testing-library/user-event/dist/cjs/utils/index.js";
import React, { useRef, useState } from "react";
import axios from 'axios';
// import "./Layout.css";

// Pass the child props
export default function Contact() {
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [cardType, setCardType] = useState('')
  const [brand, setBrand] = useState('')
  const [cardValue, setCardValue] = useState('')
  const [message, setMessage] = useState('')
  const formRef = useRef()

  const submitForm = async (e) => {
    console.log("submitted")
    console.log(e)
    e.preventDefault();
    const isValid = formRef.current.checkValidity();
    console.log(isValid)
    if(isValid) {
      console.log("valid form")
      let reqObj = {
        name, mobile, email, cardType, brand, cardValue, message
      }
      console.log(reqObj)
      const response=await axios.post(`https://api.voucherbuy.co.in/api/reseller/bookOrder`, reqObj,{headers:{"Content-Type":"application/json"}})
        if(response.data.success===true){
            alert("We Received Your Order, will process soon!");
            window.location.reload()
        }
    } else {
      console.log("invalid form")
    }
  }
  return (
    <section id="contact" className="contact section">

      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p><span>Need Help?</span> <span className="description-title">Contact Us</span></p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
      
        <div className="mb-5">
          <iframe style={{width: '100%', height: '400px'}} src="https://maps.google.com/maps?q=3-225%2FSH%2F501%2C+Sterling+Heights%2C+Kavuri+Hills%2C+Guttalabegumpet%2C+Madhapur%2C+Hyderabad&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" allowFullScreen=""></iframe>
        </div>

        <div className="row gy-4">

          <div className="col-md-6">
            <div className="info-item d-flex align-items-center" data-aos="fade-up" data-aos-delay="200">
              <i className="icon bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h3>Address</h3>
                <p>3-225/SH/501, Sterling Heights, Kavuri Hills, Guttalabegumpet, Madhapur, Hyderabad, Telangana – 500081</p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="info-item d-flex align-items-center" data-aos="fade-up" data-aos-delay="300">
              <i className="icon bi bi-telephone flex-shrink-0"></i>
              <div>
                <h3>Call Us</h3>
                <p>+91 9014282350</p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="info-item d-flex align-items-center" data-aos="fade-up" data-aos-delay="400">
              <i className="icon bi bi-envelope flex-shrink-0"></i>
              <div>
                <h3>Email Us</h3>
                <p>support@voucherbuysoftware.com</p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="info-item d-flex align-items-center" data-aos="fade-up" data-aos-delay="500">
              <i className="icon bi bi-clock flex-shrink-0"></i>
              <div>
                <h3>Opening Hours<br /></h3>
                {/* <p><strong>Mon-Sat:</strong> 06AM - 11:59PM; <strong>Sunday:</strong> Closed</p> */}
                <p><strong>24/7</strong></p>
              </div>
            </div>
          </div>

        </div>
        <div id="contact-form">
          <form ref={formRef} onSubmit={(e) => submitForm(e)} method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="600">
            <div className="row gy-4">

              <div className="col-md-6">
                <input type="text" name="name" className="form-control" placeholder="Your Name" required value={name} onChange={(e) => setName(e.target.value)} />
              </div>

              <div className="col-md-6 ">
                <input type="email" className="form-control" name="email" placeholder="Your Email" required  value={email} onChange={(e) => setEmail(e.target.value)}/>
              </div>

              <div className="col-md-6 ">
                <input type="text" className="form-control" name="mobile" placeholder="Mobile" required  value={mobile} onChange={(e) => setMobile(e.target.value)}/>
              </div>
              <div className="col-md-6 ">
                <select className="form-control" name="branch" required  value={brand} onChange={(e) => setBrand(e.target.value)}>
                  <option value="">Select Brand</option>
                  <option value="amazon">Amazon</option>
                  <option value="flipkart">Flipkart</option>
                </select>
              </div>
              <div className="col-md-6 ">
                <input type="number" min="0" max="10000" className="form-control" name="card_value" placeholder="Card Value" required  value={cardValue} onChange={(e) => setCardValue(e.target.value)} />
              </div>
              <div className="col-md-6 ">
                <select className="form-control" name="card_type" required  value={cardType} onChange={(e) => setCardType(e.target.value)}>
                  <option value="">Select Card Type</option>
                  <option value="thankyou">Thank You</option>
                  <option value="anniversary">Anniversary</option>
                  <option value="congratulations">Congratulations</option>
                  <option value="happybirthday">Happy Birthday</option>
                </select>
              </div>

              {/* <div className="col-md-12">
                <input type="text" className="form-control" name="subject" placeholder="Subject" required="" />
              </div> */}

              <div className="col-md-12">
                <textarea className="form-control" name="message" rows="6" placeholder="Message" required=""  value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
              </div>

              <div className="col-md-12 text-center">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>

                <button type="submit">Send Message</button>
              </div>

            </div>
          </form>
        </div>

        

      </div>

    </section>

  
  );
}


