import React from "react";
// import "./Layout.css";

// Pass the child props
export default function Hero() {
  return (
    <section id="hero" className="hero section">

      <div className="container">
        <div className="row gy-4 justify-content-center justify-content-lg-between">
          <div className="col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up">Welcome to Voucher Buy - The Premier Destination for Gift Cards!</h1>
            <p data-aos="fade-up" data-aos-delay="100">Are you searching for the ideal gift for any event? Your search ends here! "Voucher buy" Gift Cards are the perfect choice to express your care. Whether it’s a birthday, anniversary, holiday celebration, or simply a gesture of appreciation, our gift cards provide the freedom and flexibility for your loved ones to select exactly what they desire.</p>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
              <a href="#contact-form" className="btn-get-started">Get Card</a>
            </div>
          </div>
          <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out">
            <img src="assets/img/brands/senddigitalcards.png" className="img-fluid animated" alt="" />
          </div>
        </div>
      </div>

      </section>

  
  );
}


