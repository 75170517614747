import React from 'react';
// import logo from './logo.svg';
// import { Counter } from './features/counter/Counter';
// import './App.css';
import Layout from '../../components/Layout/Layout';
import Hero from '../../features/herosection/hero';
import Cards from '../../features/vbcards';
// import Clients from '../../features/clients/Clients';
import Contact from '../../features/contact/Contact';
import About from '../../features/about/About';
import Chooseus from '../../features/about/Chooseus';
import Brands from '../../features/brands'

function Home() {
  return (
    <Layout>
        <Hero />
        <Brands />
        {/* <VBCards /> */}
        {/* <Clients /> */}
        <About />
        <Cards />
        <Chooseus />
        <Contact />
    </Layout>
    
  );
}

export default Home;
