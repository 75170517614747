import React from "react";
// import "./Layout.css";

// Pass the child props
export default function Cards() {
  return (
    <section id="services" className="menu section">

      <div className="container section-title" data-aos="fade-up">
        <h2>Gift Cards</h2>
      </div>

      <div className="container">

      <div className="row gy-5">

        <div className="col-lg-3">
            <a className="glightbox"><img src="assets/img/brands/thankyou.jpg" className="menu-img img-fluid" alt="" /></a>
            <p className="ingredients">
                Thank You Card
            </p>
            <p className="price buy-btn">
                ₹ 1000
            </p>
            <p className="price buy-btn">
                <a className="btn-getstarted" href="#contact-form">Buy</a>
            </p>
        </div>
        <div className="col-lg-3">
            <a className="glightbox"><img src="assets/img/brands/anniversary.jpg" className="menu-img img-fluid" alt="" /></a>
            <p className="ingredients">
                Anniversary Card
            </p>
            <p className="price buy-btn">
                ₹ 1000
            </p>
            <p className="price buy-btn">
                <a className="btn-getstarted" href="#contact-form">Buy</a>
            </p>
        </div>
        <div className="col-lg-3">
            <a className="glightbox"><img src="assets/img/brands/congratulations.jpg" className="menu-img img-fluid" alt="" /></a>
            <p className="ingredients">
                Congratulations Card
            </p>
            <p className="price buy-btn">
                ₹ 1000
            </p>
            <p className="price buy-btn">
                <a className="btn-getstarted" href="#contact-form">Buy</a>
            </p>
        </div>
        <div className="col-lg-3">
            <a className="glightbox"><img src="assets/img/brands/happybirthday.jpg" className="menu-img img-fluid" alt="" /></a>
            <p className="ingredients">
                Happy Birthday Card
            </p>
            <p className="price buy-btn">
                ₹ 1000
            </p>
            <p className="price buy-btn">
                <a className="btn-getstarted" href="#contact-form">Buy</a>
            </p>
        </div>

        </div>

      </div>

    </section>

  
  );
}

